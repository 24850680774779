




















import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Registration',
  methods: {},
  computed: {
    ...mapGetters('auth', ['is_authorized'])
  }
})
