import { render, staticRenderFns } from "./Registration.vue?vue&type=template&id=d7f0cdec&scoped=true&"
import script from "./Registration.vue?vue&type=script&lang=ts&"
export * from "./Registration.vue?vue&type=script&lang=ts&"
import style0 from "./Registration.scss?vue&type=style&index=0&id=d7f0cdec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7f0cdec",
  null
  
)

export default component.exports